import { fetchMessengerChatApi } from 'api/chat/fetchMessengerChatApi'
import { fetchMessengerChatWithSupportApi } from 'api/messengerSupport/fetchMessengerChatWithSupportApi'
import { supportChatId } from 'components/page/Chat/Messenger/Messenger.constants'

import { errorCodeEquals, profileBannedCode } from '..'

export const fetchMessengerSwitchApi = async (
  profileId: number,
  limit: number,
  offset: number,
  userHasRestrictions: boolean
) => {
  if (userHasRestrictions) {
    return fetchMessengerChatWithSupportApi(limit, offset)
  }

  if (Number(profileId) !== 0) {
    const response = await fetchMessengerChatApi(profileId, limit, offset)

    /**
     * В случае PWA запросы проставляющие ошибку для userHasRestrictions
     * не успевают отработать при рефреше страницы.
     *
     * Поэтому проверяем непосредственно после запроса
     * и, если ошибка, идем за чатом поддержки.
     *
     * https://youtrack.mamba.ru/issue/M-9085
     */
    if (
      errorCodeEquals(response, profileBannedCode) &&
      profileId === supportChatId
    ) {
      return fetchMessengerChatWithSupportApi(limit, offset)
    }

    return response
  }

  throw new Error('Messenger fetch API method is not found')
}
