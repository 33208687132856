import { AsyncThunkAction } from 'actions/actions.types'
import { filterContactListAction } from 'actions/contactList/additionalContactListAction'
import { updateChatToggleFavoriteAction } from 'actions/messenger/updateChatToggleFavoriteAction'
import {
  addFavoriteContactApi,
  removeFavoriteContactApi,
} from 'api/contactList/toggleFavoriteContactApi'
import {
  ContactListType,
  ContactsFilter,
} from 'reducers/contactList/contactList.types'

export const ADD_FAVORITE_CONTACT = 'ADD_FAVORITE_CONTACT' as const

export const addFavoriteContactAction = (userId: number) => ({
  type: ADD_FAVORITE_CONTACT,
  userId,
  promise: () => addFavoriteContactApi(userId),
})

export const REMOVE_FAVORITE_CONTACT = 'REMOVE_FAVORITE_CONTACT' as const

export const removeFavoriteContactAction = (userId: number) => ({
  type: REMOVE_FAVORITE_CONTACT,
  userId,
  promise: () => removeFavoriteContactApi(userId),
})

export const combineToggleAddFavoriteAction =
  (userId: number, isIgnoreContactList: boolean): AsyncThunkAction =>
  async (dispatch) => {
    await dispatch(addFavoriteContactAction(userId))
    dispatch(updateChatToggleFavoriteAction(userId, true))

    if (isIgnoreContactList) {
      dispatch(filterContactListAction(ContactListType.ignored, userId))
    }
  }

export const combineToggleRemoveFavoriteAction =
  (userId: number): AsyncThunkAction =>
  async (dispatch, getState) => {
    const { contactList } = getState()
    await dispatch(removeFavoriteContactAction(userId))
    dispatch(updateChatToggleFavoriteAction(userId, false))

    if (contactList?.filter === ContactsFilter.Favorites) {
      dispatch(removeFromContactFavoriteListAction(userId))
    }
  }

export const FILTER_FAVORITE_CONTACT_LIST =
  'FILTER_FAVORITE_CONTACT_LIST' as const

export const removeFromContactFavoriteListAction = (profileId: number) => ({
  type: FILTER_FAVORITE_CONTACT_LIST,
  profileId,
})

export type ToggleFavoriteContactListTypes =
  | ReturnType<typeof addFavoriteContactAction>
  | ReturnType<typeof removeFavoriteContactAction>
  | ReturnType<typeof removeFromContactFavoriteListAction>
