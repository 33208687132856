import {
  AsyncAction,
  AsyncThunkAction,
  AsyncThunkActionVoid,
} from 'actions/actions.types'
import { ADD_FAVORITE_CONTACT } from 'actions/contactList/toggleFavoriteContactAction'
import { jsonSuccessAction } from 'actions/jsonSuccessAction'
import { loadMoreNewMessagesAction } from 'actions/messenger/loadMoreNewMessagesAction'
import { resetTypingAction } from 'actions/messenger/resetTypingAction'
import { SendMessageAction } from 'actions/messenger/sendMessageAction'
import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import { API5_ERROR } from 'api/constants'
import { ApiResult } from 'api/fetchApi'
import { acceptIncognitoApi } from 'api/incognito/incognitoRequestApi'
import {
  markReadMessageCurrentChatSwitchApi,
  sendDeclineApi,
  sendPhotosSwitchApi,
  sendTypingApi,
  supportOpenTicketApi,
  supportRateTicketApi,
} from 'api/messenger/chatMessengerApi'
import { CustomPhoto, Photo } from 'api/photo/photo.types'
import { SUPPORT_BOT_PROFILE_ID } from 'components/page/Chat/Messenger/Messenger.constants'
import { NoticeShortCutList } from 'components/uninotice/uninotice.types'
import { hasUserRestrictions } from 'functions/hasUserRestrictions'
import { ChatMessage } from 'reducers/messenger/ChatMessengerState'

import { AppDispatchNext, AppGetState } from './actions.typesNext'
import { ChatEventReasons } from './messengerStopChat/constants'
import { showStopChatIfNeeded } from './messengerStopChat/showStopChatIfNeeded'

export const SEND_TYPING = 'SEND_TYPING'

export const SEND_DECLINE = 'SEND_DECLINE'
export const SUPPORT_RATE_TICKET = 'SUPPORT_RATE_TICKET'

export const MARK_ALL_READ = 'MARK_ALL_READED'

interface MarkAllReadAction {
  type: typeof MARK_ALL_READ
}

export const markAllReadAction = () => ({
  type: MARK_ALL_READ,
})

export const TOGGLE_DEFERRED_MARK_ALL_READED = 'TOGGLE_DEFERRED_MARK_ALL_READED'

interface ToggleDeferredMarkAllReaded {
  type: typeof TOGGLE_DEFERRED_MARK_ALL_READED
  payload: {
    value: boolean
  }
}

export const toggleDeferredMarkAllReaded = (value: boolean) => ({
  type: TOGGLE_DEFERRED_MARK_ALL_READED,
  payload: {
    value,
  },
})

export const TOGGLE_NEW_INCOMING = 'TOGGLE_NEW_INCOMING' as const

export const toggleNewIncomingAction = (value: boolean) => ({
  type: TOGGLE_NEW_INCOMING,
  value,
})

export const TOGGLE_FORCE_REFETCH = 'TOGGLE_FORCE_REFETCH'

interface ToggleForceRefetchAction {
  type: typeof TOGGLE_FORCE_REFETCH
  payload: {
    value: boolean
  }
}

export const toggleForceRefetchAction = (value: boolean) => ({
  type: TOGGLE_FORCE_REFETCH,
  payload: {
    value,
  },
})

export const FORCE_REPLACE_MESSAGES = 'FORCE_REPLACE_MESSAGES'

interface ForceReplaceMessagesAction {
  type: typeof FORCE_REPLACE_MESSAGES
  payload: {
    messages: ChatMessage[]
  }
}

export const forceReplaceMessagesAction = (messages: ChatMessage[]) => ({
  type: FORCE_REPLACE_MESSAGES,
  payload: {
    messages,
  },
})

export const sendTypingAction =
  (recipientId: number): AsyncThunkActionVoid =>
  (dispatch, getState) => {
    const state = getState()

    if (hasUserRestrictions(state)) {
      // В данном случае, отправлять этот запрос не нужно
      return
    }

    return dispatch({
      type: SEND_TYPING,
      promise: () => sendTypingApi(recipientId),
    })
  }

export const sendDeclineAction = (profileId: number) => ({
  type: SEND_DECLINE,
  promise: () => sendDeclineApi(profileId),
})

export const acceptIncognitoAction =
  (profileId: number): AsyncThunkAction =>
  (dispatch) =>
    dispatch({
      type: ADD_FAVORITE_CONTACT,
      promise: () =>
        acceptIncognitoApi(profileId).then(
          jsonSuccessAction((json: unknown) => {
            dispatch(
              fetchUniNoticeAction(NoticeShortCutList.moveFavoriteOneContact, {
                anketa_id: profileId,
              })
            )
            return json
          })
        ),
      userId: profileId,
    })

export const addToFavoriteAndRefetchAction =
  (profileId: number): AsyncThunkAction =>
  async (dispatch) => {
    await dispatch(acceptIncognitoAction(profileId))
    await dispatch(loadMoreNewMessagesAction(profileId))
  }

export const declineAndRefetchAction =
  (profileId: number): AsyncThunkAction =>
  async (dispatch) => {
    await dispatch(sendDeclineAction(profileId))
    await dispatch(loadMoreNewMessagesAction(profileId))
  }

export const SEND_PHOTOS = 'SEND_PHOTOS'

interface SendPhotosAction extends AsyncAction {
  type: typeof SEND_PHOTOS
}

export const sendPhotosAction =
  (profileId: number, albumId: number, photosIds: number[]) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const state = getState()

    return dispatch({
      type: SEND_PHOTOS,
      promise: async () => {
        const response = await sendPhotosSwitchApi(
          profileId,
          albumId,
          photosIds,
          hasUserRestrictions(state)
        )

        /**
         * Пока нашел единственный случай
         * когда stopChatNotice приходит не в корне, а в теле объекта
         * В данном случае используется api5 завязываемся на code и internalError
         * В sendPhotosSwitchApi есть внутреннее разделение на заблокированного user и нет.
         * В случе заблокированного мы проверяем ошибки как для api6
         * https://youtrack.mamba.ru/issue/M-6204
         */
        if (
          (response.code === API5_ERROR && 'internalError' in response) ||
          'error' in response
        ) {
          dispatch(showStopChatIfNeeded(ChatEventReasons.Send))
        }

        return response
      },
    })
  }

export const SUPPORT_OPEN_TICKET = 'SUPPORT_OPEN_TICKET'

interface SupportOpenTicketAction extends AsyncAction {
  type: typeof SUPPORT_OPEN_TICKET
}

export const supportOpenTicketAction =
  (): AsyncThunkAction => async (dispatch) => {
    const { result: json } = (await dispatch({
      type: SUPPORT_OPEN_TICKET,
      promise: () => supportOpenTicketApi(),
    })) as unknown as { result: ApiResult }

    if (!json.error) {
      dispatch(loadMoreNewMessagesAction(SUPPORT_BOT_PROFILE_ID))
    }
  }

export const supportRateTicketAction = (value: number) => ({
  type: SUPPORT_RATE_TICKET,
  promise: () => supportRateTicketApi(value),
})

export const UPDATE_PHOTO_IN_MESSAGE = 'UPDATE_PHOTO_IN_MESSAGE'

interface UpdatePhotoInMessageAction {
  type: typeof UPDATE_PHOTO_IN_MESSAGE
  result: { photo: Photo }
}

export const updatePhotoInMessageAction = (photo: CustomPhoto) => ({
  type: UPDATE_PHOTO_IN_MESSAGE,
  result: { photo },
})

export const CLEAR_PHOTO_IN_MESSAGE = 'CLEAR_PHOTO_IN_MESSAGE' as const

export const clearPhotoInMessageAction = () => ({
  type: CLEAR_PHOTO_IN_MESSAGE,
})

export const MARK_READ_MESSAGE_CURRENT_CHAT = 'MARK_READ_MESSAGE_CURRENT_CHAT'

interface MarkReadedMessageSenderProfileAction extends AsyncAction {
  type: typeof MARK_READ_MESSAGE_CURRENT_CHAT
}

export const markReadedMessageSenderProfileAction =
  (): AsyncThunkAction => (dispatch, getState) => {
    const state = getState()

    const { chatMessengerReducer } = state

    const contactId = chatMessengerReducer?.updateReadedByContactId

    if (contactId) {
      return dispatch({
        type: MARK_READ_MESSAGE_CURRENT_CHAT,
        promise: () =>
          markReadMessageCurrentChatSwitchApi(
            contactId,
            hasUserRestrictions(state)
          ),
      })
    }
  }

export const UPDATE_CHAT_MESSENGER_SELECTED_PROFILE =
  'UPDATE_CHAT_MESSENGER_SELECTED_PROFILE' as const

export const updateChatMessengerSelectedProfileAction = (
  profileId: number
) => ({
  type: UPDATE_CHAT_MESSENGER_SELECTED_PROFILE,
  profileId,
})

export type ChatMessengerActionTypes =
  | SendMessageAction
  | ForceReplaceMessagesAction
  | ReturnType<typeof toggleNewIncomingAction>
  | ReturnType<typeof resetTypingAction>
  | MarkAllReadAction
  | ToggleDeferredMarkAllReaded
  | ToggleForceRefetchAction
  | UpdatePhotoInMessageAction
  | ReturnType<typeof clearPhotoInMessageAction>
  | SendPhotosAction
  | MarkReadedMessageSenderProfileAction
  | SupportOpenTicketAction
  | ReturnType<typeof updateChatMessengerSelectedProfileAction>
