import { AsyncThunkAction } from 'actions/actions.types'
import { toggleForceRefetchAction } from 'actions/chatMessengerAction'

/**
 * @param userId
 * @param toggleFavorite
 * При добавлении в избранное с контактом, у которого есть autoDeleteDate,
 * мы должны перезапросить чат, чтобы обновить поле autoDeleteDate.
 * Оптимизация, при добавлении в избранное, если поля autoDeleteDate нет,
 * мы не перезапрашиваем чат.
 * Мы не знаем, если у контакта, которого мы удаляем из избранного есть ли поле
 * autoDeleteDate, поэтому при удалении из избранного мы на любой контакт
 * перезапрашиваем чат
 */
export const updateChatToggleFavoriteAction =
  (userId: number, toggleFavorite: boolean): AsyncThunkAction =>
  (dispatch, getState) => {
    const { chatMessengerReducer } = getState()

    if (chatMessengerReducer) {
      const { selectedProfileId } = chatMessengerReducer

      if (toggleFavorite) {
        const autoDeleteDate = chatMessengerReducer?.chatData?.autoDeleteDate

        if (Boolean(autoDeleteDate) && selectedProfileId === userId) {
          dispatch(toggleForceRefetchAction(true))
        }
      } else {
        if (selectedProfileId === userId) {
          dispatch(toggleForceRefetchAction(true))
        }
      }
    }

    return Promise.resolve()
  }
