import { Api6Error } from 'api/api.types'
import { apiResultWithoutErrors } from 'api/function/apiResultWithoutErrors'

export const jsonSuccessAction = <T>(action: (json: T) => T) => {
  return (json: T) => {
    if (apiResultWithoutErrors(json as unknown as Api6Error)) {
      action(json)
    }
    return json
  }
}
