import { NoticeType, UniNoticePayload } from 'api/uninotice/uninotice.types'
import { GQLGender } from 'generated-graphql/graphql'
import { Interest } from 'reducers/interests/interests.types'

export enum ContactsFilter {
  All = 'all',
  Online = 'online',
  Favorites = 'favorites',
  Ignored = 'ignored',
}

export enum ContactListType {
  common = 'contactList',
  ignored = 'contactListIgnored',
  new = 'contactListNew',
}

export enum ContactListSelectedIdsType {
  common = 'contactSelectedIds',
  ignored = 'contactSelectedIgnoredIds',
}

export enum ContactType {
  All = 'any',
}

export enum FolderType {
  Favorite = 'Favorite',
  VipBlocked = 'VipBlocked',
}

export enum EditFolderType {
  Common = 'common',
  Ignored = 'Ignored',
}

export interface ContactItem {
  unread: number
  contactId: number
  contactTypeId: 1 | 6
  contactName: string
  anketa?: Profile | null
  canStopChat: boolean
  stopChat: boolean
  stoppedChat: boolean
  isChatBlocked: boolean
  isMsgBlocked: boolean
  isPrivatePhotoEnabled?: boolean
  folderId: number
  messages: number
  totalMessages: number
  stickersSupport: boolean
  lastMessageTimestamp: number
  lastMessage: LastMessage | null
  lastReaction: LastReaction | null
  isNewContact: boolean
  autoDeleteDate: string
  autoDeleteDateTs?: number
  initiatedByOwner: boolean
  isMutedByMe: boolean
  chatBlockedKey: string
  chatBlockedReason: string
  stopChatNotice: StopChatNotice
}

export interface Profile {
  id: number
  name: string
  userId: number
  deleted?: boolean
  age: number
  isReal: boolean
  metro: string
  city: string
  region: string
  country: string
  lookFor: string[]
  lookForWithAge: string
  isOnlineString: string
  onlineStatus: OnlineStatus
  spaceTimeLocation: SpaceTimeLocation
  lastVisit: string
  isVip: boolean
  squarePhotoUrl: string
  smallPhotoUrl: string
  isInFavorite: boolean
  isInIgnored: boolean
  isAnketaAIgnored: boolean
  gender: GQLGender
  interests: Interests
  incognitoIsOn: boolean
  incognitoCanView: boolean
  isAgencyAgent: boolean
  newYearPhotoFrameEnabled: boolean
  newYearThemeSet: boolean
  isBot: boolean
  themeId?: any
  verifiedPhotos: boolean
  photo?: ProfilePhoto
}

export interface LastMessage {
  id: number
  user_from_id: number
  user_to_id: number
  folder_to_id: number
  contact_id: string
  created: string
  createdTs: number
  incoming: boolean
  unread: boolean
  type: string
  /** @deprecated @use message_text */
  message: string
  message_text: string
  options?: Options
}

export interface LastReaction {
  id: number
  userId: number
  type: string
  value: string
  createdAt: number
  readAt: number | null
}

export interface StopChatNotice {
  noticeId: string
  type: NoticeType
  payload: UniNoticePayload
}

export interface ProfilePhoto {
  id: number
  name: string
  erotic: boolean
  smallPhotoUrl: string
  mediumPhotoUrl: string
  hugePhotoUrl: string
  largePhotoUrl: string
  squarePhotoUrl: string
  squarePhotoUrlMasked: string
  squareSmallPhotoUrl: string
  ratingId: number
  albumId: number
  width: number
  height: number
  default?: boolean
  votedByUser: boolean
  likesCount: number
  verified: boolean
  squareLargePhotoUrl: string
  commentsCount: number
}

export interface OnlineStatus {
  online: boolean
  status: string
}

export interface SpaceTimeLocation {
  text: string
}

export interface Interests {
  count: number
  items: Interest[]
  title: string
}

export interface Options {
  anketaId?: number
  imageName: string
  comment: string
  alias: string
  contactUrl: string
  contactValue: string
}
