import { postApi } from 'api/fetchApi'
import { paths as messengerMessages } from 'api/generated/messenger_messages'

type Response =
  messengerMessages['/messenger/editMessage']['post']['responses']['204']
type Body =
  messengerMessages['/messenger/editMessage']['post']['parameters']['body']['body']

export const editMessageApi = (
  recipientId?: number,
  messageId?: number,
  editedText?: string
) => {
  return postApi<Response, Body>('/messenger/editMessage', {
    recipientId,
    messageId,
    editedText,
  })
}
